import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, Switch, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import * as ApplicationApi from '../utils/ApplicationApi';
import config from '../utils/config.json';


const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    padding: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  switchLabel: {
    marginRight: '10px',
  },
  searchInput: {
    width: '200px',
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  smallerTable: {
    width: '80%',
  },
  createTicketButton: {
    color: '#FF9800',
  },
  separatorContainer: {
    width: '60%',
  },
  separator: {
    margin: '40px auto',
    borderTop: '1px dashed #ccc',
    width: '100%',
  }
}));

const Account = () => {
  const classes = useStyles();
  const [tickets, setTickets] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [isShowClosed, setIsShowClosed] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedData, setExpandedData] = useState({});
  const [loadingExpanded, setLoadingExpanded] = useState(false);

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      try {
        const response = await ApplicationApi.ListTickets();

        if (response.status) {
          setTickets(response.data);
        } else {
          console.error(response.reason);
        }
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
      setLoading(false);
    };

    fetchTickets();
  }, []);

  const handleToggleClosed = () => {
    setIsShowClosed(!isShowClosed);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleRowClick = async (ticketId) => {
    if (expandedRow === ticketId) {
      setExpandedRow(null);
      setExpandedData({});
    } else {
      setLoadingExpanded(true);
      setExpandedRow(ticketId);
      try {
        const response = await ApplicationApi.GetTicket({ ticketId });
        if (response.status) {
          setExpandedData(response.data);
        } else {
          console.error(response.reason);
        }
      } catch (error) {
        console.error('Error fetching ticket details:', error);
      }
      setLoadingExpanded(false);
    }
  };

  const renderExpandedRow = () => {
    if (loadingExpanded) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow>
        <TableCell colSpan={6}>
          <Typography>
            Company: {expandedData.company?.value}<br />
            First Name: {expandedData.firstName?.value}<br />
            Last Name: {expandedData.lastName?.value}<br />
            Email: {expandedData.email?.value}<br />
            Message: {expandedData.message?.value}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5" style={{ marginTop: '15px' ,marginBottom: '10px', textAlign: 'center', paddingBottom: '15px'}}>My Tickets History</Typography>
      <div className={classes.header}>
        <div className={classes.toggleContainer}>
          <Typography className={classes.switchLabel}>Show Closed Tickets</Typography>
          <Switch
            checked={isShowClosed}
            onChange={handleToggleClosed}
            inputProps={{ 'aria-label': 'toggle show closed tickets' }}
          />
        </div>
        <TextField
          className={classes.searchInput}
          label="Search"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={handleSearch}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: '20px' }}
          component={Link}
          to={config.app_routes.createTicket}
          size="large"
        >
          Create Ticket
        </Button>
      </div>
      {loading && (
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <CircularProgress />
          </div>
        )}
      <TableContainer component={Paper} className={classes.smallerTable}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Case Number</TableCell>
              <TableCell className={classes.tableHeader}>Subject</TableCell>
              <TableCell className={classes.tableHeader}>Date Created</TableCell>
              <TableCell className={classes.tableHeader}>Date Closed</TableCell>
              <TableCell className={classes.tableHeader}>Status</TableCell>
              <TableCell className={classes.tableHeader}>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets
              .filter((ticket) => {
                const ticketData = JSON.stringify(ticket).toLowerCase();
                return (
                  (isShowClosed || !ticket.closedDate?.value) &&
                  ticketData.includes(searchText.toLowerCase())
                );
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((ticket) => (
                <React.Fragment key={ticket.internalId?.value}>
                  <TableRow onClick={() => handleRowClick(ticket.internalId?.value)} style={{ cursor: 'pointer' }}>
                    <TableCell>{ticket.caseNumber?.value}</TableCell>
                    <TableCell>{ticket.subject?.value}</TableCell>
                    <TableCell>{ticket.createdDate?.value}</TableCell>
                    <TableCell>{ticket.closedDate?.value || "N/A"}</TableCell>
                    <TableCell>{ticket.status?.text}</TableCell>
                    <TableCell>
                      {expandedRow === ticket.internalId?.value ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </TableCell>
                  </TableRow>
                  {expandedRow === ticket.internalId?.value && renderExpandedRow()}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tickets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </div>
  );
};

export default Account;
