import React, { useEffect, useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Typography,
  Paper,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { FileUploader } from "react-drag-drop-files";
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import * as ApplicationApi from '../utils/ApplicationApi';
import Dialog from '../utils/Dialog';
import config from '../utils/config.json';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '20px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '70%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  submitButton: {
    marginTop: '10px',
  },
  fileInput: {
    display: 'none',
    marginTop: '20px',
  },
  fileInputLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  blackDisabledText: {
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#000000",
    }
  },
  uploadLabel: {
    fontWeight: 500,
    fontSize: '1.1rem',
    marginBottom: '1rem',
    marginTop: '0.5rem',
    textAlign: 'left'
  }
}));

const CreateTicket = () => {
  const classes = useStyles();

  const [caseCategories, setCaseCategories] = useState({});
  const [selectedCaseCategory, setSelectedCaseCategory] = useState('');
  const [caseTypes, setCaseTypes] = useState([]);
  const [selectedCaseType, setSelectedCaseType] = useState('');
  const [contactDetails, setContactDetails] = useState([]);
  const [selectedContact, setSelectedContact] = useState('');
  const [isNewContact, setIsNewContact] = useState(true);
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [supportingDocument, setSupportingDocument] = useState(null);
  const [supportingDocumentType, setSupportingDocumentType] = useState('');
  const [disclaimerRadioValue, setDisclaimerRadioValue] = useState('no');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [loading, setLoading] = React.useState(false);
  const allowedFileTypes = ['pdf', 'jpg', 'png', 'jpeg'];
  const [enableDisclaimer, setEnableDisclaimer] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch case types
        const caseTypesResponse = await ApplicationApi.GetCaseTypes();
        if (caseTypesResponse.status) {
          setCaseCategories(caseTypesResponse.data);
        } else {
          console.error(caseTypesResponse.reason);
        }

        // Fetch contact details
        const contactDetailsResponse = await ApplicationApi.ValidateCustomer();
        if (contactDetailsResponse.status) {
          setContactDetails(contactDetailsResponse.data.contactDetails);
        } else {
          console.error(contactDetailsResponse.reason);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleCaseCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCaseCategory(category);
    setSelectedCaseType('');
    setCaseTypes(caseCategories[category].caseType);
  };

  const handleContactChange = (event) => {
    const contactId = event.target.value;
    setSelectedContact(contactId);

    // Autofill fields if a contact is selected
    const selectedContactData = contactDetails.find((contact) => contact.internalId?.value === contactId);
    if (selectedContactData) {
      setContactFirstName(selectedContactData.firstName?.value || '');
      setContactLastName(selectedContactData.lastName?.value || '');
      setContactEmail(selectedContactData.email?.value || '');
      setContactPhone(selectedContactData.phone?.value || '');
    } else {
      // Clear fields if "New Contact" is selected
      setContactFirstName('');
      setContactLastName('');
      setContactEmail('');
      setContactPhone('');
    }

    // Update state to disable fields if a selected contact is chosen
    setIsNewContact(contactId === 'new');
  };

  const handleNewContactToggle = () => {
    setIsNewContact(!isNewContact);
    setSelectedContact('');
  };

  const getFileExtension = (filename) => {
    return filename.split('.').pop();
  };

  const handleFileChange = (file) => {
    if (file) {
      const fileExtension = getFileExtension(file.name);
      if (!allowedFileTypes.includes(fileExtension)) {
        setIsDialogOpen(true);
        setDialogContent("File type not supported");
        return;
      }
      setSupportingDocument(file);
      
      // capitalize the whole string
      setSupportingDocumentType(fileExtension.toUpperCase());
    }
  };

  const handleRadioChange = (e) => {
    setDisclaimerRadioValue(e.target.value);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      
      fileReader.onloadend = () => {
        resolve(fileReader.result.split(',')[1]); // Extract the base64 content
      };
  
      fileReader.onerror = (error) => {
        reject(error);
      };
  
      // Read the file as Data URL (base64)
      fileReader.readAsDataURL(file);
    });
  };
  
  const handleSubmit = async () => {
    if (disclaimerRadioValue === 'no' && enableDisclaimer) {
      setIsDialogOpen(true);
      setDialogContent("Please agree to the terms and conditions");
      return;
    }

    setLoading(true);
    try {
      
      let base64String = null;
      // Convert the file to base64
      if (supportingDocument){
        base64String = await convertFileToBase64(supportingDocument);
      }
  
      // Perform the API call
      const response = await ApplicationApi.CreateTicket({
        subject,
        isNewContact,
        contactDetails: isNewContact
          ? {
              firstName: contactFirstName,
              lastName: contactLastName,
              email: contactEmail,
              phone: contactPhone,
            }
          : {
              id: selectedContact,
              firstName: contactFirstName,
              lastName: contactLastName,
              email: contactEmail,
              phone: contactPhone,
            },
        caseCategory: selectedCaseCategory,
        caseType: selectedCaseType,
        message,
        supportingDocument: base64String,
        supportingDocumentType: supportingDocumentType,
      });
  
      setIsDialogOpen(true);
      
      if (response.status) {
        setDialogContent(response.reason);

        // Redirect after successful submission
        setTimeout(() => {
          window.location.href = config.app_routes.account
        }, 4000);
      } else {
        setDialogContent(response.error);
      }
    } catch (error) {
      setIsDialogOpen(true);
      setDialogContent("Error creating ticket");
    }
    setLoading(false);
  };
  

  return (
    <div className={classes.container}>
      <Dialog handleDialogPopUp={isDialogOpen} dialogContent={dialogContent} setIsDialogOpen={setIsDialogOpen}></Dialog>
      {loading && 
        (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
            <CircularProgress/>
          </div>
        )
      }     
      <Paper elevation={3} className={classes.form}>
        <Typography variant="h5">Create Ticket</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="caseCategory">Case Category</InputLabel>
          <Select
            value={selectedCaseCategory}
            onChange={handleCaseCategoryChange}
            inputProps={{ name: 'caseCategory', id: 'caseCategory' }}
            label="Case Category"
          >
            {Object.keys(caseCategories).map((category) => (
              <MenuItem key={category} value={category}>
                {caseCategories[category].caseCategoryText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="caseType">Case Type</InputLabel>
          <Select
            value={selectedCaseType}
            onChange={(e) => setSelectedCaseType(e.target.value)}
            inputProps={{ name: 'caseType', id: 'caseType' }}
            label="Case Type"
          >
            {caseTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="contact">Contact</InputLabel>
          <Select
            value={selectedContact || 'new'} // Set default value to 'new' for "New Contact"
            onChange={handleContactChange}
            inputProps={{ name: 'contact', id: 'contact' }}
            label="Contact"
          >
            <MenuItem value="new" onClick={handleNewContactToggle}>
              New Contact
            </MenuItem>
            {contactDetails.map((contact) => (
              <MenuItem key={contact.internalId?.value} value={contact.internalId?.value}>
                {/* firstname lastname, (email) */}
                {contact.firstName?.value} {contact.lastName?.value}, ({contact.email?.value})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isNewContact ? (
          <>
            <TextField
              label="First Name"
              fullWidth
              margin="normal"
              value={contactFirstName}
              onChange={(e) => setContactFirstName(e.target.value)}
            />
            <TextField
              label="Last Name"
              fullWidth
              margin="normal"
              value={contactLastName}
              onChange={(e) => setContactLastName(e.target.value)}
            />
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
            <TextField
              label="Phone"
              fullWidth
              margin="normal"
              value={contactPhone}
              onChange={(e) => setContactPhone(e.target.value)}
            />
          </>
        ) : (
          <>
            <TextField
              label="First Name"
              fullWidth
              margin="normal"
              value={contactFirstName}
              onChange={(e) => setContactFirstName(e.target.value)}
              className={classes.blackDisabledText}
              disabled
            />
            <TextField
              label="Last Name"
              fullWidth
              margin="normal"
              value={contactLastName}
              onChange={(e) => setContactLastName(e.target.value)}
              className={classes.blackDisabledText}
              disabled
            />
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              className={classes.blackDisabledText}
              disabled
            />
            <TextField
              label="Phone"
              fullWidth
              margin="normal"
              value={contactPhone}
              onChange={(e) => setContactPhone(e.target.value)}
              className={classes.blackDisabledText}
              disabled
            />
          </>
        )}
        {/* Additional Info Section */}
        <TextField
          label="Subject"
          fullWidth
          margin="normal"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <TextField
          label="Message"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        <FormControl>
            <Typography className={classes.uploadLabel}>Choose file to attach documents (File of upto 5 MB each)</Typography>
            <FileUploader
                multiple={false}
                handleChange={e => handleFileChange(e)}
                name="file"
                types={allowedFileTypes}
                label={"Upload File"}
            />
        </FormControl>

        {enableDisclaimer && 
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 5, p: 2, bgcolor: 'grey.100' }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
          · All invoices are to be paid as per the approved Credit terms.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
          · Claims arising from invoices must be made within 7 working days.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
          · We agree to settle all payment on/before the due dates.
          </Typography>

          <RadioGroup onChange={e => handleRadioChange(e)} sx={{ flexDirection: 'row' }} value={disclaimerRadioValue}>
            <FormControlLabel
              control={<Radio />}
              value="yes"
              label="Agree"
            />
            <FormControlLabel
              control={<Radio />}
              value="no"
              label="Disagree"
            />
          </RadioGroup>
        </Box>}

        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={handleSubmit}
          style={{ marginTop: '20px', width: '15%', margin: '0 auto' }}
        >
          Submit
        </Button>

      </Paper>
    </div>
  );
};

export default CreateTicket;
