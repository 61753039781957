import React from 'react';
import Header from '../Account/Header';
import Footer
 from '../Account/Footer';
const WithHeader = (Component) => {
    const WithHeaderComponent = () => {
        return (
            <>
                <Header />
                <Component />
                <Footer />
            </>
        );
    };

    return WithHeaderComponent;
};

export default WithHeader;