import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import config from './Pages/utils/config.json';

import WithAuthCheck from './Pages/utils/WithAuthCheck';
import WithHeader from './Pages/utils/WithHeader';

// import ComplaintForm from './Pages/Complaint/Form';
import Auth from './Pages/Auth/Auth';
import Account from './Pages/Account/Account';
import CreateTicket from './Pages/Account/CreateTicket';

// import { ComplaintProvider } from './Pages/Complaint/Contexts/ComplaintContext';

function App() {
  // auth check for each account route
  const AuthAccountPage = WithAuthCheck(Account);
  const AuthCreateTicketPage = WithAuthCheck(CreateTicket);

  const AccountPage = WithHeader(AuthAccountPage);
  const CreateTicketPage = WithHeader(AuthCreateTicketPage);

  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/" element={<ComplaintFormPage/>} /> */}
          <Route path={config.app_routes.login} element={<Auth/>} />
          <Route path={config.app_routes.account} element={<AccountPage/>} />
          <Route path={config.app_routes.createTicket} element={<CreateTicketPage/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;