import React from 'react';
import { Navigate } from 'react-router-dom';
import config from './config.json';

const WithAuthCheck = (Component) => {
  const AuthCheck = () => {
    const token = localStorage.getItem('Authorization');
    if (token) {
      return <Component />;
    } else {
      return <Navigate to={config.app_routes.login} />;
    }
  };

  return AuthCheck;
};

export default WithAuthCheck;