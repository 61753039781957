import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Link,
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FormStepName, FormStepDescription, CustomInputLabel, HelperImage } from './FormComponentsStyles';
import * as FormStyles from './FormStyles';

import config from '../utils/config.json';
import Dialog from '../utils/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import * as ApplicationApi from '../utils/ApplicationApi';

const LoginForm = () => {
  const [customerId, setCustomerId] = useState('');
  const [customerName, setCustomerName] = useState('');

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});

  const [loading, setLoading] = React.useState(false);

  const handleLogin = async () => {
    setLoading(true);
    const response = await ApplicationApi.Auth({
      customerId: customerId,
      customerName: customerName,
    });
    setLoading(false);

    console.log(response);
    
    if (response.status) {
      localStorage.setItem('Authorization', 'Bearer ' + response.data.token);
      window.location.href = config.app_routes.account;
    } else {
      setIsDialogOpen(true);
      setDialogContent(response.reason);
    }
  };

  return (
    <>
    <FormStyles.Container>
      <FormStyles.FormContainer>
        <Dialog handleDialogPopUp={isDialogOpen} dialogContent={dialogContent} setIsDialogOpen={setIsDialogOpen}></Dialog>
        <FormStyles.FormHeader>
          <Link to="/">
            <FormStyles.FormLogo src={require("./images/bidfood-logo.png")} alt="Logo" />
          </Link>
        </FormStyles.FormHeader>
        <FormStyles.FormDivider />
        <div>
          <FormStyles.FormName>
            Complaints Application
          </FormStyles.FormName>
        </div>


        <FormStyles.FormStepCard>
          {/* <FormStyles.FormStepName>General Information</FormStyles.FormStepName>
              <FormStyles.FormStepDescription>Let's begin by filling out your general information</FormStyles.FormStepDescription> */}

          {/* if current step is 1, show the GatewayComponent and stat generalcounter also do not make it count from 0 again*/}

          {loading && 
            (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
                <CircularProgress/>
              </div>
            )
          }

          <section className='gateway_sec'>
                <FormStepName>Customer Information</FormStepName>
                <FormStepDescription>Please fill all the required fields</FormStepDescription>
                <Grid container rowSpacing={2} columnSpacing={{ md: 4 }}>
                <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="customer_id"
                      type='number'
                      onChange={(e) => {
                        // set value as a number
                        const newValue = e.target.value.replace(/\s/g, '');
                        setCustomerId(newValue)
                      }}
                      value={customerId}
                    />
                    <CustomInputLabel htmlFor="customer_id">
                      Customer ID
                    </CustomInputLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="customer_name"
                      onChange={(e) => setCustomerName(e.target.value)}
                      value={customerName}
                    />
                    <CustomInputLabel htmlFor="customer_name" className='input_label'>
                      Customer Name
                    </CustomInputLabel>
                  </Grid>
                  <Grid item xs={12} sm={0}>
                    <Accordion>
                      <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      >
                        <Typography>Need help finding your information ?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Link to="/">
                          <HelperImage src={require("./images/infohelper.jpg")} alt="Find your info" />
                        </Link>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid >
          </section>
        </FormStyles.FormStepCard>

        <FormStyles.FormFooter>
          {<FormStyles.FormButton onClick={handleLogin}>Login</FormStyles.FormButton>}
        </FormStyles.FormFooter>
      </FormStyles.FormContainer>
    </FormStyles.Container>
    </>
  );
};

export default LoginForm;