import React from 'react';
import { Typography, Paper, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: '2px',
    marginTop: 'auto',
    backgroundColor: '#34495e',
    color: '#ecf0f1',
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Paper component="footer" className={classes.footer}>
      <Container maxWidth="md">
        <Typography variant="body2" align="center">
          © {new Date().getFullYear()} Bidfood Middle East. All rights reserved.
        </Typography>
      </Container>
    </Paper>
  );
};

export default Footer;
