import React from 'react';
import { AppBar, Toolbar, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { jwtDecode } from "jwt-decode";
import config from '../utils/config.json';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
    textAlign: 'center', // Center the text
  },
  logo: {
    height: '50px', // Adjust the height of the logo
  }
}));

const Header = () => {
  const classes = useStyles();

  const handleLogout = () => {
    // Implement your logout logic here
    localStorage.removeItem('Authorization');
    window.location.href = config.app_routes.login;
  };

  // Extract account name from the Bearer token in localStorage
  const getAccountName = () => {
    try {
      const token = localStorage.getItem('Authorization');
      const decodedToken = jwtDecode(token);
      const accountName = decodedToken.customerName;
      return accountName;
    }
    catch {
      return "Guest";
    }
  };

  return (
    <AppBar position="static" sx={{ background: '#131B4D', borderBottom: '3px solid #89c140' }}>
      <Toolbar>
        <Link to="/">
          <img src={require("./images/bidfood-logo.png")} alt="Logo" className={`${classes.logo} ${classes.formLogo}`} />
        </Link>
        <Typography variant="h6" className={classes.title} sx={{ color: '#ecf0f1' }}>
          Welcome, {getAccountName()}
        </Typography>
        <Button component={Link} to={config.app_routes.account} className={classes.homeButton} size="large" sx={{ color: '#ecf0f1', textDecoration: 'none', marginRight: '10px' }}>
          Home
        </Button>
        <Button onClick={handleLogout} size="large" className={classes.logoutButton} sx={{ color: '#e74c3c' }}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
